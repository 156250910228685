import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const CreateAgent = React.lazy(() => import('../../views/CreateAgent'));

const params = [];
const CreateAgentRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <CreateAgent />
      </Layout>
    </RouteParameter>
  );
};

CreateAgentRoute.propTypes = {};

CreateAgentRoute.defaultProps = {};

export default CreateAgentRoute;
