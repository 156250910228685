import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const UsageHistory = React.lazy(() => import('../../views/UsageHistory'));

const params = [];
const UsageHistoryRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <UsageHistory />
      </Layout>
    </RouteParameter>
  );
};

UsageHistoryRoute.propTypes = {};

UsageHistoryRoute.defaultProps = {};

export default UsageHistoryRoute;
