import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../../ui-components/MenuButton';
import PropTypes from 'prop-types';
import { setAuth, getAuth } from '../../helpers/auth';
import routes from '../../config/routes';
import URL from '../../utils/url';
import './style.css';

const classNames = require('classnames');

const Header = ({ toggleMenu, showHeader }) => {
  const auth = getAuth();
  const navigate = useNavigate();

  const navigateToLink = (url) => {
    navigate(new URL(url).replaceParams({}).getUrl());
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(new URL(routes.login).appendParams({}).getUrl());
  };

  return (
    <div
      className={classNames('h-screen fixed menu_con', {
        'menu-backdrop-show': showHeader,
        'menu-backdrop-hide': !showHeader,
      })}
    >
      <div className="backdrop" onClick={toggleMenu}>
        &nbsp;
      </div>
      <div
        className={classNames('w-full h-screen fixed z-10 header', {
          'header-show': showHeader,
          'header-hide': !showHeader,
        })}
      >
        <div className="flex items-center justify-center p-4 header-logo">
          <img
            src="/assets/icons/informed-closer-logo.png"
            alt="informed-closer-logo"
            width="150px"
            onClick={() => navigateToLink(routes.accounts)}
          />
        </div>
        <div className="py-6 menu_con z-10">
          <nav>
            {auth?.data?.role === 1 && (
              <MenuButton
                label="Accounts"
                type="button"
                normal={window.location.pathname !== routes.accounts}
                href="/"
                width="100%"
                height="56px"
                icon="users"
                relativeImgPath="/assets/icons/menu-icons/"
                onClick={() => navigateToLink(routes.accounts)}
              />
            )}
            <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== routes.dashboard}
              href="/"
              width="100%"
              height="56px"
              icon="history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.dashboard)}
            />
            <MenuButton
              label="Agents"
              type="button"
              normal={window.location.pathname !== routes.agents}
              href="/"
              width="100%"
              height="56px"
              icon="users"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.agents)}
            />
            <MenuButton
              label="Reports"
              type="button"
              normal={window.location.pathname !== routes.reports}
              href="/"
              width="100%"
              height="56px"
              icon="history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.reports)}
            />

            <MenuButton
              label="Payment"
              type="button"
              normal={window.location.pathname !== routes.payment}
              href="/"
              width="100%"
              height="56px"
              icon="history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.payment)}
            />
            {/* // Super admin doesn't see these */}
            {auth?.data?.role !== 1 && (
              <>
                <MenuButton
                  label="Agents Stats"
                  type="button"
                  normal={window.location.pathname !== routes.callAgents}
                  href="/"
                  width="100%"
                  height="56px"
                  icon="history"
                  relativeImgPath="/assets/icons/menu-icons/"
                  onClick={() => navigateToLink(routes.callAgents)}
                />
                <MenuButton
                  label="History"
                  type="button"
                  normal={window.location.pathname !== routes.history}
                  href="/"
                  width="100%"
                  height="56px"
                  icon="history"
                  relativeImgPath="/assets/icons/menu-icons/"
                  onClick={() => navigateToLink(routes.history)}
                />

                <MenuButton
                  label="Usage History"
                  type="button"
                  normal={window.location.pathname !== routes.usageHistory}
                  href="/"
                  width="100%"
                  height="56px"
                  icon="history"
                  relativeImgPath="/assets/icons/menu-icons/"
                  onClick={() => navigateToLink(routes.usageHistory)}
                />
                <MenuButton
                  label="PreScrub"
                  type="button"
                  normal={window.location.pathname !== routes.prescrub}
                  href="/"
                  width="100%"
                  height="56px"
                  icon="history"
                  relativeImgPath="/assets/icons/menu-icons/"
                  onClick={() => navigateToLink(routes.prescrub)}
                />
                <MenuButton
                  label="Settings"
                  type="button"
                  normal={window.location.pathname !== routes.settings}
                  href="/"
                  width="100%"
                  height="56px"
                  icon="settings"
                  relativeImgPath="/assets/icons/menu-icons/"
                  onClick={() => navigateToLink(routes.settings)}
                />
              </>
            )}

            <MenuButton
              label="Log Out"
              type="button"
              normal={true}
              href="#"
              width="100%"
              height="56px"
              icon="log-out"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={handleLogout}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func,
  showHeader: PropTypes.bool,
};

Header.defaultProps = {
  toggleMenu: undefined,
  showHeader: false,
};

export default Header;
