import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { setAuth, getAuth } from '../helpers/auth';
import routes from '../config/routes';
import LoginRoute from './Login';
import AccountsRoute from './Accounts';
import CreateAccountRoute from './CreateAccount';
import AgentsRoute from './Agents';
import CreateAgentRoute from './CreateAgent';
import Fallback from './Fallback';
import PaymentRoute from './payment';
import CreatePaymentRoute from './CreatePayment';
import PaymentResRoute from './PaymentResponse';
import PaymentFailResRoute from './PaymentFailedResponse';
import HistoryRoute from './history';
import UsageHistoryRoute from './UsageHistory';
import PrescrubRoute from './PreScrub';
import SettingsPageRoute from './SettingsPage';
import ReportsRoute from './Reports';
import DashboardRoute from './Dashboard';
import SettingPageUploadRoute from './SettingPageUpload';
import SettingsListRoute from './SettingsList';
import CallAgentStats from './CallAgentStats';

const Routing = () => {
  const auth = getAuth();
  return (
    <Routes>
      <Route path={routes.login} element={<LoginRoute />} />
      {auth?.data?.role === 1 && (
        <Route path={routes.accounts} element={<AccountsRoute />} />
      )}
      {auth?.data?.role === 1 && (
        <Route path={routes.createAccount} element={<CreateAccountRoute />} />
      )}
      <Route path={routes.dashboard} element={<DashboardRoute />} />
      <Route path={routes.agents} element={<AgentsRoute />} />
      <Route path={routes.createAgent} element={<CreateAgentRoute />} />
      <Route path={routes.reports} element={<ReportsRoute />} />
      <Route path={routes.callAgents} element={<CallAgentStats />} />
      <Route path={routes.payment} element={<PaymentRoute />} />

      <Route path={routes.createPayment} element={<CreatePaymentRoute />} />
      <Route path={routes.PaymentResponse} element={<PaymentResRoute />} />
      <Route path={routes.PaymentFailResponse} element={<PaymentFailResRoute />} />
      <Route path={routes.history} element={<HistoryRoute />} />
      <Route path={routes.usageHistory} element={<UsageHistoryRoute />} />
      <Route path={routes.prescrub} element={<PrescrubRoute />} />
      <Route path={routes.settings} element={<SettingsPageRoute />} />
      <Route path={routes.settingsList} element={<SettingsListRoute />} />
      <Route path={routes.settingsUpload} element={<SettingPageUploadRoute />} />
      <Route path="*" element={<Fallback />} />
    </Routes>
  );
};

export default Routing;
