import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const PaymentFailRes = React.lazy(() => import('../../views/Payment/PaymentFailedResponse'));

const params = [];
const PaymentFailResRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <PaymentFailRes />
      </Layout>
    </RouteParameter>
  );
};

PaymentFailResRoute.propTypes = {};

PaymentFailResRoute.defaultProps = {};

export default PaymentFailResRoute;
