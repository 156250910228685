import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './menuButton.css';

const classNames = require('classnames');

export const menuButtonTheme = Object.freeze({
  normal: 'bg-transparent menu-button menu-button-color',
  active: 'bg-theme-main-blue menu-button text-white',
});

export const iconButtonPosition = Object.freeze({
  left: 'justify-start gap-2',
  center: 'justify-center gap-2',
});

// iconPosition prop must be "left or center"
// icon prop must have "url" of icon or "path" of icon
function MenuButton(props) {
  const {
    label,
    normal,
    width,
    minWidth,
    height,
    icon,
    iconPosition,
    disabled,
    onClick,
    href,
    relativeImgPath,
    extraClasses,
  } = props;

  const [iconColorChange, setIconColorChange] = useState('');

  useEffect(() => {
    if (!normal) {
      setIconColorChange('-white');
    }
  }, []);

  const hoverFunc = () => {
    if (normal) {
      setIconColorChange('-white');
    }
  };
  const hoverLeaveFunc = () => {
    if (normal) {
      setIconColorChange('');
    }
  };
  return (
    <div className="h-full flex" style={{ height, width, minWidth }}>
      <button
        style={{ width: '100%', height: '100%' }}
        type="button"
        className={classNames(
          'flex justify-start items-center gap-2.5 font-normal px-5 hover:bg-theme-main-blue hover:text-white',
          {
            [menuButtonTheme.normal]: normal === true && disabled === false,
            [menuButtonTheme.active]: normal === false && disabled === false,
            [iconButtonPosition.left]:
              iconPosition.length > 0 &&
              icon.length > 0 &&
              iconPosition === 'left',
            [menuButtonTheme.disabled]: disabled === true,
            [extraClasses]: extraClasses !== '',
          }
        )}
        disabled={disabled}
        onClick={() => onClick(href)}
        onMouseEnter={hoverFunc}
        onMouseLeave={hoverLeaveFunc}
      >
        <span className="w-6 flex justify-center items-center">
          {icon.length > 0 && (
            <img
              src={`${relativeImgPath}${icon}${iconColorChange}.svg`}
              alt={icon}
            />
          )}
        </span>
        {label.length > 0 && <span>{label}</span>}
        {icon && <span />}
      </button>
    </div>
  );
}

export default MenuButton;

MenuButton.propTypes = {
  normal: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  relativeImgPath: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  minWidth: PropTypes.string,
  iconPosition: PropTypes.string,
  disabled: PropTypes.bool,
  extraClasses: PropTypes.string,
};

MenuButton.defaultProps = {
  onClick: undefined,
  icon: '',
  iconPosition: iconButtonPosition.left,
  disabled: false,
  minWidth: '30px',
  extraClasses: '',
};
