import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import { setAuth, getAuth } from '../../helpers/auth';
import URL from '../../utils/url';

const Fallback = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.data?.role === 1) {
      navigate(new URL(routes.accounts).appendParams({}).getUrl());
    } else {
      navigate(new URL(routes.agents).appendParams({}).getUrl());
    }
  }, []);

  return <> </>;
};

export default Fallback;
