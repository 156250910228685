import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import UnProtectedLayout from '../../features/Layout/unProtected';

const Login = React.lazy(() => import('../../views/Login'));

const params = [];
const LoginRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <UnProtectedLayout>
        <Login />
      </UnProtectedLayout>
    </RouteParameter>
  );
};

LoginRoute.propTypes = {};

LoginRoute.defaultProps = {};

export default LoginRoute;
