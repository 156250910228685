import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ToggleMenuContextProvider } from './ToggleMenuContext';
import Header from '../Header';
import FullPageLoader from '../../components/Loader/fullPageLoader';
import './style.css';

const Layout = ({ children }) => {
  const { height } = useWindowDimensions();
  const [showHeader, setShowHeader] = useState(true);
  const toggleMenu = (e) => {
    e.preventDefault();
    setShowHeader(!showHeader);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setShowHeader(false);
    }
  }, []);

  return (
    <div className="w-full flex" style={{ height }}>
      <Header toggleMenu={toggleMenu} showHeader={showHeader} />
      <Suspense fallback={<FullPageLoader />}>
        <div className="content-con">
          <ToggleMenuContextProvider
            value={{
              toggleMenu,
            }}
          >
            {children}
          </ToggleMenuContextProvider>
        </div>
      </Suspense>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
