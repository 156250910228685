import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import FullPageLoader from '../../components/Loader/fullPageLoader';
import './style.css';

const UnProtectedLayout = ({ children }) => {
  const { height } = useWindowDimensions();

  return (
    <div className="w-full flex" style={{ height }}>
      <Suspense fallback={<FullPageLoader />}>
        <div
          className={classNames(
            'h-screen overflow-auto w-full z-0 smooth-transition'
          )}
        >
          {children}
        </div>
      </Suspense>
    </div>
  );
};

UnProtectedLayout.propTypes = {
  children: PropTypes.element,
};

UnProtectedLayout.defaultProps = {
  children: undefined,
};

export default UnProtectedLayout;
