import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const SettingsPage = React.lazy(() => import('../../views/SettingsPage'));

const params = [];
const SettingsPageRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <SettingsPage />
      </Layout>
    </RouteParameter>
  );
};

SettingsPageRoute.propTypes = {};

SettingsPageRoute.defaultProps = {};

export default SettingsPageRoute;
