const routes = {
  login: '/login',
  dashboard: '/dashboard',
  accounts: '/accounts',
  createAccount: '/account/create',
  agents: '/agents',
  createAgent: '/agent/create',
  reports: '/reports',
  callAgents: '/call-agents-stats',
  payment: '/payment',
  history: '/history',
  createPayment: '/payment/create',
  PaymentResponse: '/payment/response/success',
  PaymentFailResponse: '/payment/response/failed',
  usageHistory:'/usage-history',
  prescrub:'/pre-scrub',
  settings: '/settings',
  settingsList: '/settings/list/:list_id',
  settingsUpload: '/settings/upload/:list_id',
};
export default routes;
